<template>
    <div>
        <b-row align-v="end">
            <b-col cols="6">
                <dmx-title backlink :bc-items="bcItems">{{ device.name }}</dmx-title>
            </b-col>
            <b-col cols="6" class="d-flex align-items-end justify-content-end pb-4">
                <b-button v-if="device.id != 0" variant="danger" v-b-modal.delete-device-modal class="mr-1">
                    {{ $t('common_delete') }}
                </b-button>
                <b-button variant="success" @click="saveDevice()">
                    {{ (deviceId != 0) ? $t('common_save') : $t('common_create') }}
                </b-button>
            </b-col>
        </b-row>
        <div class="dmx-card mt-3">
            <b-row no-gutters align-v="center">
                <b-col lg="2" align="left" class="pr-2">
                    {{ $t('common_name') }}:
                </b-col>
                <b-col lg="10">
                    <b-form-input v-model="device.name"></b-form-input>
                </b-col>
            </b-row>
            <b-row no-gutters align-v="center" class="mt-3">
                <b-col sm="2" align="left" class="pr-2">
                    {{ $t('common_username') }}:
                </b-col>
                <b-col lg="10">
                    <b-form-input v-model="device.username"></b-form-input>
                </b-col>
            </b-row>
            <b-row no-gutters align-v="center" class="mt-3">
                <b-col lg="2" align="left" class="pr-2">
                    {{ $t('common_password') }}:
                </b-col>
                <b-col lg="10">
                    <b-form-input v-model="device.password"></b-form-input>
                </b-col>
            </b-row>
            <b-row no-gutters align-v="center" class="mt-3">
                <b-col lg="2" align="left" class="pr-2">
                    {{ $t('common_password') }}:
                </b-col>
                <b-col lg="10">
                    <b-form-input v-model="device.password"></b-form-input>
                </b-col>
            </b-row>
            <b-row no-gutters align-v="center" class="mt-3">
                <b-col lg="2" align="left" class="pr-2">
                    {{ $t('common_sim') }}:
                </b-col>
                <b-col lg="10">
                    <b-form-input v-model="device.sim"></b-form-input>
                </b-col>
            </b-row>
            <b-row v-show="deviceId > 0" no-gutters align-v="center" class="mt-3">
                <b-col lg="2" align="left" class="pr-2">
                    {{ $t('common_fw_to_install_semver') }}:
                </b-col>
                <b-col lg="10">
                    <div>
                        <b-form-select v-model="device.firmwareVersionToInstall" :options="device.availableFirmwareVersions"
                                       class="mb-3">
                            <template #first>
                                <b-form-select-option :value="null" disabled>
                                    -- Choose Firmware Version
                                    --
                                </b-form-select-option>
                            </template>
                        </b-form-select>
                    </div>
                </b-col>
            </b-row>

            <hr />
            <div v-if="deviceId != 0" class="d-flex justify-content-end align-items-center small">
                <span class="mr-3 ">Id: {{ deviceId }}</span>
                <span class="mr-3 ">Guid: {{ device.clientId }}</span>
                <span class="mr-3 ">FW: {{ device.firmwareVersion }}</span>
                <span>Created/Modified: {{ utcFormatted(device.createdOnUtc) }}</span>
            </div>
        </div>

        <b-modal size="md" id="delete-device-modal" title="Delete device" @ok="deleteDevice()">
            <p>
                You are about to delete this device (<b>{{ device.name }}</b>).<br />
                <b>This operation cannot be undone!</b>
            </p>

            <template #modal-footer="{ hide, ok }">
                <b-button variant="primary" @click="hide()">
                    {{ $t('common_cancel') }}
                </b-button>
                <b-button variant="danger" @click="ok()">
                    {{ $t('common_delete') }}
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<style scoped>
    .dmx-card {
        background-color: #f3f2e8;
        border-radius: 4px;
        padding: 16px;
    }
</style>

<script>
    import deviceService from '@/services/deviceService';
    import firmwareService from '@/services/firmwareService';
    import router from '@/router';

    export default {
        name: 'EditDevice',
        props: ["deviceId"],
        components: {
        },
        data: () => ({
            device: {
                name: 'New Device',
                username: '',
                password: '',
                sim: '',
                createdOnUtc: '',
                clientId: '',
                availableFirmwareVersions: null,
                firmwareVersionToInstall: null,
            }
        }),
        methods: {
            deleteDevice() {
                deviceService.deleteDevice(this.deviceId)
                    .then(data => {
                        router.go(-1)
                    });
            },
            saveDevice() {
                //console.log(this.device);
                if (this.deviceId > 0) {
                    deviceService.updateDevice({
                        id: this.device.id,
                        name: this.device.name,
                        firmwareVersionToInstall: this.device.firmwareVersionToInstall,
                        username: this.device.username,
                        password: this.device.password,
                        sim: this.device.sim,
                    })
                        .then(data => {
                            router.go(-1)
                        });
                } else {
                    deviceService.createDevice({
                        id: this.deviceId,
                        name: this.device.name,
                        //firmwareVersionToInstall: this.device.firmwareVersionToInstall,
                        username: this.device.username,
                        password: this.device.password,
                        sim: this.device.sim,
                    })
                        .then(data => {
                            router.go(-1)
                        });
                }
            },
        },
        computed: {
            bcItems() {
                return [
                    {
                        text: 'devices',
                        href: '/devices',
                    },
                    {
                        text: this.device.name,
                        href: '/device/editDevice/' + this.deviceId,
                        active: true,
                    }
                ]
            },
        },
        mounted() {
            firmwareService.getSemanticVersions()
                .then(data => {
                    //console.log(data);
                    this.device.availableFirmwareVersions = data;
                }).then(_ => {
                    if (this.deviceId > 0) {
                        deviceService.getDevice(this.deviceId)
                            .then(data => {
                                //console.log(data);
                                // Remove current fw version from available choices
                                let filteredFirmwareVersions = this.device.availableFirmwareVersions.filter((item, _) => {
                                    if (item !== data.firmwareVersion) {
                                        //console.log(item);
                                        return item;
                                    }
                                });
                                this.device = data;
                                this.device.availableFirmwareVersions = filteredFirmwareVersions;
                            });
                    }
                });
        }
    }
</script>
